//let apiUrl = "http://28.com:8888";
let apiUrl = "http://api.moonway.cn";
//let apiUrl = "http://testapi.moonway.cn";

let baseUrl = {
   apiUrl
}

export default baseUrl


<template>
  <a-config-provider :locale="locale">
  <router-view/>
  </a-config-provider>
</template>
<script>
  import { ref } from "vue";
  import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
  export default {
    setup() {
      const locale = ref(zhCN);
      return {
        locale
      };
    }
  }
</script>
<style lang="less">
  .tc{
    text-align: center;
  }
  .red{
    color: red;
  }
</style>

<template>
    <a-form layout="inline">
        <a-form-item label="用户姓名">
            <a-input v-model:value="uname" placeholder="请输入用户姓名"/>
        </a-form-item>
        <a-form-item label="提交时间段">
            <a-range-picker
                    :show-time="{ format: 'HH:mm' }"
                    format="YYYY-MM-DD HH:mm"
                    :placeholder="['开始日期时间', '结束日期时间']"
                    @change="onChange"
                    @ok="onOk"
                    :ranges="ranges"
            />
        </a-form-item>
        <a-form-item>
            <a-button type="primary" @click="search">
                <template #icon><search-outlined /></template>
                搜索
            </a-button>
        </a-form-item>
    </a-form>
    <a-table :columns="columns" :data-source="data" :scroll="{ x: 1500, y: 600 }">
        <template #complete="{record}">
            <p v-if="record.complete == 1">
                <span class="red">是</span>
            </p>
            <p v-else>
                <span>否</span>
            </p>
        </template>
        <template #site="{record}">
            <a :href="record.site" target="_blank">{{record.site}}</a><br/>
        </template>
        <template #refer="{record}">
            <a :href="record.refer" target="_blank">{{record.refer}}</a><br/>
        </template>
        <template #action="{record}">
            <router-link :to="'/sdetail/id/'+record.id">详情</router-link>
        </template>
    </a-table>
</template>
<script>
    import {defineComponent, onMounted, ref} from 'vue';
    import {list,del} from '@/network/service';
    import moment from 'moment';
    import {message} from "ant-design-vue";
    import {SearchOutlined} from "@ant-design/icons-vue"
    /*import locale from 'ant-design-vue/es/locale/zh_CN';*/

    export default defineComponent({
        name: 'Slist',
        components: {
            SearchOutlined
        },
        data() {
            const formState = ref({});
            const where = ref({'uname':'','startTime':'','endTime':''});
            const search =()=>{
                where.value.uname = uname.value;
                getList(where.value);
            };
            const uname = ref('');
            const onChange = (value, dateString) => {
                console.log('Formatted Selected Time: ', dateString);
                where.value.startTime = dateString[0];
                where.value.endTime = dateString[1];
            };

            const onOk = value => {
                console.log('onOk: ', value);
            };
            const data = ref([]);
            const columns = [
                {
                    title: 'Id',
                    width: 90,
                    dataIndex: 'id',
                    key: 'id',
                    fixed: 'left',
                },
                {
                    title: '姓名',
                    width: 80,
                    dataIndex: 'uname',
                    key: 'uname',
                    fixed: 'left',
                },
                {
                    title: '要修改的站',
                    dataIndex: 'site',
                    key: 'site',
                    width: 100,
                    slots: {
                        customRender: 'site',
                    },
                },
                {
                    title: '参考的网站',
                    dataIndex: 'refer',
                    key: 'refer',
                    width: 100,
                    slots: {
                        customRender: 'refer',
                    },
                },
                {
                    title: '保留修改网站订单',
                    dataIndex: 'orders',
                    key: 'orders',
                    width: 80,
                    customRender: (text, record, index) => {
                        //console.log(text);
                        if (text.text == 1) {
                            return "是";
                        } else {
                            return "否";
                        }
                    }

                },
                {
                    title: '网站修改备注',
                    dataIndex: 'description',
                    key: 'description',
                    width: 120,
                    ellipsis: true,
                },
                {
                    title: '是否完成',
                    dataIndex: 'complete',
                    key: 'complete',
                    width: 50,
                    /*slots: {
                        customRender: 'complete',
                    },*/
                    slots: {
                        customRender: 'complete',
                    },
                },
                {
                    title: '提交时间',
                    dataIndex: 'create_time',
                    key: 'create_time',
                    width: 100,
                    customRender: (text, record, index) => {
                        //console.log(text.text);
                        return moment.unix(text.text).format('YYYY-MM-DD HH:mm:ss');
                    }
                },
                {
                    title: '操作',
                    key: 'operation',
                    fixed: 'right',
                    width: 120,
                    slots: {
                        customRender: 'action',
                    },
                },
            ];
            onMounted(() => {
                getList(where.value);
            });
            const getList = (where)=>{
                list(where).then((response) => {
                    if (response.status == 'done') {
                        data.value = response.list;
                    } else {
                        message.error(response.msg);
                    }
                })
            }
            return {
                uname,
                data,
                columns,
                onChange,
                onOk,
                formState,
                ranges: {
                    Today: [moment().startOf('day'), moment()],
                    'This Month': [moment(), moment().endOf('month')],
                },
                search,
            };
        },
    });
</script>
<style scoped lang="less">
</style>

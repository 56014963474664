<template>
    <a-form ref="formRef"
            :model="formState"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol">
        <a-form-item label="用户名：" name="name">
            <a-input v-model:value="formState.name"/>
        </a-form-item>
        <a-form-item label="姓&emsp;名：" name="uname">
            <a-input v-model:value="formState.uname"/>
        </a-form-item>
        <a-form-item label="密&emsp;码：" name="password">
            <a-input-password v-model:value="formState.password"/>
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 14, offset: 11 }">
            <a-button type="primary" @click="onSubmit">注册</a-button>
            <a-button style="margin-left: 10px" @click="resetForm">重置</a-button>
        </a-form-item>
    </a-form>

</template>

<script>
    import {defineComponent, reactive, ref, toRaw} from 'vue'
    import {message} from "ant-design-vue";
    import {addUser} from "@/network/service";

    export default {
        name: 'Login',
        setup() {
            const formRef = ref()
            const formState = reactive({
                name: '',
                password: 'wsww4538',
                uname: ''
            })
            const data = ref({});
            const rules = {
                name: [
                    {
                        required: true,
                        message: '账号不能为空',
                        trigger: 'blur',
                    },
                    {
                        min: 3,
                        max: 50,
                        message: '账号长度为3-50',
                        trigger: 'blur',
                    },
                ],
                uname: [
                    {
                        required: true,
                        message: '姓名不能为空',
                        trigger: 'blur',
                    },
                    {
                        min: 2,
                        max: 4,
                        message: '账号长度为2-4',
                        trigger: 'blur',
                    },
                ],
                password: [
                    {
                        required: true,
                        message: '密码不能为空',
                        trigger: 'blur',
                    },
                    {
                        min: 5,
                        max: 20,
                        message: '密码长度为5-20',
                        trigger: 'blur',
                    },
                ]
            }

            const onSubmit = () => {
                formRef.value
                    .validate()
                    .then(() => {
                        console.log(formState);
                        addUser(formState).then((response)=>{
                            if(response.status=='done'){
                                message.success(response.msg);
                                resetForm();
                            }else{
                                message.error(response.msg);
                            }
                        });
                        //console.log('values', formState, toRaw(formState))
                    })
                    .catch(error => {
                        console.log('error', error)
                    })
            }

            const resetForm = () => {
                formRef.value.resetFields()
            }

            return {
                formRef,
                labelCol: {
                    span: 4,
                    offset: 5
                },
                wrapperCol: {
                    span: 6,
                },
                formState,
                rules,
                onSubmit,
                resetForm,
            }
        },
    }
</script>

<style scoped lang="less">
    .title {
        margin-top: 50px;
        font-size: 30px;
        margin-bottom: 30px;
        text-align: center;

        sup {
            color: #999;
        }
    }
</style>

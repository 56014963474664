import {request} from './request';
import http from "./http";

export function addUser(data) {
    return http.post('/index/service/addUser',data);
}

export function list(data) {
    return http.post('/index/service/lst',data);
}
export function userList(data) {
    return http.post('/index/service/userList',data);
}
export function del(data) {
    return http.post('/index/service/del',data);
}

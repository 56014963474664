<template>
    <a-button type="primary" @click="add()">添加用户</a-button>

    <a-table :columns="columns" :data-source="data" :scroll="{ x: 1500, y: 600 }">
        <template #action="{record}">
            <a @click="delUser(record.id)">删除</a>
        </template>
    </a-table>
</template>
<script>
    import {createVNode, defineComponent, onMounted, ref} from 'vue';
    import {useRouter} from 'vue-router';
    import {message, Modal} from "ant-design-vue";
    import {userList,del} from "../../network/service";
    import moment from "moment";
    import {ExclamationCircleOutlined} from '@ant-design/icons-vue';

    export default defineComponent({
        name: 'Slist',
        setup() {

            const columns = [
                {
                    title: 'Id',
                    /*width: 50,*/
                    dataIndex: 'id',
                    key: 'id',
                },
                {
                    title: '账号名',
                    // width: 60,
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: '用户姓名',
                    dataIndex: 'uname',
                    key: 'uname',
                    // width: 60,
                },
                {
                    title: '添加时间',
                    dataIndex: 'create_time',
                    key: 'create_time',
                    customRender: (text, record, index) => {
                        console.log(text.text);
                        return moment.unix(text.text).format('YYYY-MM-DD HH:mm:ss');
                    }
                },
                {
                    title: '操作',
                    key: 'operation',
                    // width: 50,
                    fixed: 'right',
                    slots: {
                        customRender: 'action',
                    },
                },
            ];
            const delUser = (id) => {
                Modal.confirm({
                    title: '你确定要删除用户?',
                    icon: createVNode(ExclamationCircleOutlined),
                    content: '请选择是或否',
                    okText: '是',
                    okType: 'danger',
                    cancelText: '否',
                    onOk() {
                        del({'id': id}).then((response) => {
                            if (response.status == 'done') {
                                message.success(response.msg);
                                getList()
                            } else {
                                message.error(response.msg);
                            }
                        })
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                })
            };
            const router = useRouter();
            const add = () => {
                router.push('/add');
            };
            const data = ref([]);
            onMounted(() => {
                getList();
            });
            const getList = () => {
                userList().then((response) => {
                    if (response.status == 'done') {
                        data.value = response.list;
                    } else {
                        message.error(response.msg);
                    }
                })
            };

            return {
                add,
                data,
                columns,
                getList,
                delUser
            };
        }
    });
</script>
<style scoped lang="less">
</style>

import http from './http'
// 获取首页数据
export function submit(data) {
    return http.post('/index/index/submit',data);
}
export function list(data) {
    return http.post('/index/index/lst',data);
}
export function detail(data) {
    return http.post('/index/index/detail',data);
}
export function getImg(data) {
    return http.post('/index/index/getImg',data);
}
export function getMsg(data) {
    return http.post('/index/index/getMsg',data);
}
export function addMsg(data) {
    return http.post('/index/index/addMsg',data);
}
export function complete(data) {
    return http.post('/index/index/complete',data);
}

<template>
    <a-form
            ref="formRef"
            :model="formState"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
    >
        <a-input type="hidden" name="uid" v-model:value="formState.uid"/>
        <a-form-item ref="site" label="要修改的站" name="site">
            <a-input v-model:value="formState.site" placeholder="https://xxxx.com"/>
        </a-form-item>
        <a-form-item ref="refer" label="参考的网站" name="refer">
            <a-input v-model:value="formState.refer" placeholder="https://xxxx.com"/>
        </a-form-item>
        <a-form-item label="网站修改内容" name="content">
            <a-select
                    mode="multiple"
                    v-model:value="formState.content"
                    style="width: 100%"
                    placeholder="请选择修改内容"
                    @change="handleChange"
            >
                <a-select-option value="1">
                    logo
                </a-select-option>
                <a-select-option value="2">
                    banner
                </a-select-option>
                <a-select-option value="3">
                    产品
                </a-select-option>
                <a-select-option value="4">
                    支付
                </a-select-option>
                <a-select-option value="5">
                    ssl证书
                </a-select-option>
            </a-select>
        </a-form-item>
        <a-form-item label="修改网站所需的图片" name="file">
            <a-upload-dragger
                    v-model:value="formState.file"
                    :multiple="true"
                    :action="baseUrl+'/index/index/upload'"
                    @change="handleImgChange"
                    accept="image/*"
                    :remove="handleFileRemove"
                    :directory="true"
            >
                <p class="ant-upload-drag-icon">
                    <inbox-outlined></inbox-outlined>
                </p>
                <p class="ant-upload-text">把图片拖放至区域内</p>
                <p class="ant-upload-hint">
                    支持多图同时上传
                </p>
            </a-upload-dragger>
        </a-form-item>
        <a-form-item label="是否保留修改网站订单" name="orders">
            <a-radio-group v-model:value="formState.orders">
                <a-radio value="1">是</a-radio>
                <a-radio value="0">否</a-radio>
            </a-radio-group>
        </a-form-item>
        <a-form-item label="网站修改备注" name="description">
            <a-textarea v-model:value="formState.description"/>
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
            <a-button html-type="submit" type="primary" :loading="submitting" @click="onSubmit">提交工单</a-button>
        </a-form-item>
    </a-form>
</template>
<script>
    import {defineComponent, reactive, ref, toRaw, onMounted} from 'vue';
    import {InboxOutlined} from '@ant-design/icons-vue';
    import {message} from 'ant-design-vue';
    import {submit} from '@/network/custom';
    import {useRouter} from "vue-router";
    import {useStore} from 'vuex';
    import baseUrl from "@/util/api"

    export default defineComponent({
        name: 'Submit',
        setup() {
            const store = useStore();
            const formRef = ref();
            const formState = reactive({
                uid: window.sessionStorage.getItem('uid'),
                site: '',
                refer: '',
                content: [],
                orders: '',
                description: '',
                file: []
            });
            let index = '';
            const submitting = ref(false);
            //const pics = ref([]);
            // const options = ;
            const handleImgChange = info => {
                //console.log('1111' + info);
                const status = info.file.status;
                //console.log('22222' + status);
                if (status !== 'uploading') {
                    console.log(info.file, info.fileList);
                    //formState.file.value = info.fileList;
                }

                if (status === 'done') {
                    //console.log(222);
                    message.success(`${info.file.name} 图片上传成功.`);
                    formState.file.push(info.file.response.uploadImg);
                    //console.log(formState.file);
                } else if (status === 'error') {
                    //console.log(3333);
                    message.error(`${info.file.name} 图片上传失败.`);
                }
            };
            const handleChange = value => {
                if (value.keys().length > 0) {
                    formState.content = value;
                }
                //console.log(typeof(value));
            };
            const rules = {
                site: [
                    {
                        required: true,
                        message: '请输入要修改网站域名',
                        //type: 'url',
                        pattern: /^(?=^.{3,255}$)(http(s)?:\/\/)(www\.)?[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+(:\d+)*(\/\w+\.\w+)*$/,
                        trigger: 'blur',
                    }
                ],
                refer: [
                    {
                        required: false,
                        message: '请输入要参考的网站域名',
                        //type: 'url',
                        pattern: /^(?=^.{3,255}$)(http(s)?:\/\/)(www\.)?[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+(:\d+)*(\/\w+\.\w+)*$/,
                        trigger: 'blur',
                    }
                ],
                content: [
                    {
                        required: true,
                        message: '请选择要修改的内容',
                        trigger: 'blur',
                        type: 'array'
                    },
                ],
                orders: [
                    {
                        required: true,
                        message: '请选择是否保留修改网站订单',
                        trigger: 'change',
                    },
                ],
            };

            const onSubmit = () => {
                formRef.value
                    .validate()
                    .then(() => {
                        submitting.value = true;
                        setTimeout(() => {
                            //console.log('values', formState, toRaw(formState));
                            submit(formState).then((response) => {
                                console.log(response);
                                submitting.value = false;
                                if (response.status == 'done') {
                                    message.success(`${response.msg} `).then(resetForm());
                                } else {
                                    message.error(`${response.msg} `);
                                }

                            }).catch(error => {
                                //发生错误时执行的代码
                                console.log(error)
                            })
                        }, 1000)
                    })
                    .catch(error => {
                        console.log('error', error);
                    });
            };

            /*const resetForm = () => {
                formRef.value.resetFields();
            };*/
            const handleFileRemove =  (file) =>{
                console.log(file.response.uploadImg)
                //formState.file.value = file
                //message.warn('您点击了删除')
                //formState.file.value.remove(file.response.uploadImg);
                index = formState.file.indexOf(file.response.uploadImg);
                console.log(index);
                formState.file.splice(index,1);

            }
            const router = useRouter();
            const resetForm = () => {
                router.go(0);
            }
            return {
                submitting,
                formRef,
                labelCol: {
                    span: 4,
                },
                wrapperCol: {
                    span: 14,
                },
                other: '',
                formState,
                rules,
                onSubmit,
                handleChange,
                handleImgChange,
                fileList: ref([]),
                //options: {1: 'logo', 2: 'banner', 3: '产品'}
                resetForm,
                baseUrl: baseUrl.apiUrl,
                handleFileRemove
            };
        },
        components: {
            InboxOutlined,
        }
    });
</script>

<style scoped>
</style>

<template>
    <div class="ant-form bg ant-form-horizontal">
        <div class="ant-row ant-form-item">
            <div class="ant-col ant-col-4 ant-form-item-label"><label>要修改的站</label></div>
            <div class="ant-col ant-col-14 ant-form-item-control-wrapper">
                <div class="ant-form-item-control"><span class="ant-form-item-children"><a target="_blank"
                                                                                           :href="formState.site">{{formState.site}}</a></span>
                </div>
            </div>
        </div>
        <div class="ant-row ant-form-item">
            <div class="ant-col ant-col-4 ant-form-item-label"><label>参考的网站</label></div>
            <div class="ant-col ant-col-14 ant-form-item-control-wrapper">
                <div class="ant-form-item-control"><span class="ant-form-item-children"><a target="_blank"
                                                                                           :href="formState.refer">{{formState.refer}}</a></span>
                </div>
            </div>
        </div>
        <div class="ant-row ant-form-item">
            <div class="ant-col ant-col-4 ant-form-item-label"><label>网站修改内容</label></div>
            <div class="ant-col ant-col-14 ant-form-item-control-wrapper">
                <div class="ant-form-item-control"><span class="ant-form-item-children">{{formState.content}}</span>
                </div>
            </div>
        </div>
        <div class="ant-row ant-form-item">
            <div class="ant-col ant-col-4 ant-form-item-label"><label>修改网站所需的图片</label></div>
            <div class="ant-col ant-col-14 ant-form-item-control-wrapper">
                <div class="ant-form-item-control"><span class="ant-form-item-children" v-for="item in imgs"><a
                        :href="baseUrl+item"><img
                        :src="baseUrl+item" width="300" class="ml"/></a></span>
                </div>
            </div>
        </div>
        <div class="ant-row ant-form-item">
            <div class="ant-col ant-col-4 ant-form-item-label"><label>是否保留修改网站订单</label></div>
            <div class="ant-col ant-col-14 ant-form-item-control-wrapper">
                <div class="ant-form-item-control"><span class="ant-form-item-children"><span
                        v-if="formState.orders == 1">是</span>
            <span v-else>否</span></span>
                </div>
            </div>
        </div>
        <div class="ant-row ant-form-item">
            <div class="ant-col ant-col-4 ant-form-item-label"><label>网站修改备注</label></div>
            <div class="ant-col ant-col-14 ant-form-item-control-wrapper">
                <div class="ant-form-item-control"><span class="ant-form-item-children">{{formState.description}}</span>
                </div>
            </div>
        </div>
        <div class="ant-row ant-form-item">
            <div class="ant-col ant-col-4 ant-form-item-label"><label>工单创建时间</label></div>
            <div class="ant-col ant-col-14 ant-form-item-control-wrapper">
                <div class="ant-form-item-control"><span class="ant-form-item-children">{{formState.create_time}}</span>
                </div>
            </div>
        </div>
        <div class="ant-row ant-form-item">
            <div class="ant-col ant-col-4 ant-form-item-label"><label>工单是否完成</label></div>
            <div class="ant-col ant-col-14 ant-form-item-control-wrapper">
                <div class="ant-form-item-control"><span class="ant-form-item-children"><span
                        v-if="formState.complete == 1">是</span>
                <span v-else>否</span>
                </span>
                </div>
            </div>
        </div>
    </div>
    <hr/>
    <div class="bg1">
        <a-list
                v-if="comments.length"
                :data-source="comments"
                :header="`${comments.length} ${comments.length > 1 ? 'replies' : 'reply'}`"
                item-layout="horizontal"
        >
            <template #renderItem="{ item }">
                <a-list-item>
                    <a-comment
                            :author="item.uname"
                            :content="item.content"
                            :datetime="item.create_time"
                    >
                        <template #avatar>
                            <a-avatar class="abg">{{item.uname.substring(0, 1)}}</a-avatar>
                        </template>
                    </a-comment>
                </a-list-item>
            </template>
        </a-list>
        <a-comment>
            <template #avatar>
                <a-avatar class="abg">
                    {{avatar}}
                </a-avatar>
            </template>
            <template #content>
                <a-form-item>
                    <a-textarea v-model:value="value" placeholder="请输入回复" class="tw"/>
                </a-form-item>
                <a-form-item>
                    <a-button html-type="submit" :loading="submitting" type="primary" @click="handleSubmit">
                        提交回复
                    </a-button>
                </a-form-item>
            </template>
        </a-comment>
    </div>
</template>

<script>
    import {defineComponent, reactive, ref, onMounted,  createVNode,} from 'vue';
    import moment from 'moment';
    import {useRouter} from 'vue-router'
    import {detail, getImg, getMsg, addMsg} from '@/network/custom'
    import baseUrl from "@/util/api"
    import {message, Modal} from 'ant-design-vue'
    import {ExclamationCircleOutlined} from '@ant-design/icons-vue';

    export default defineComponent({
        name: "Detail",
        setup() {
            const formState = ref({
                site: '',
                refer: '',
                content: '',
                orders: '',
                description: '',
                complete: 0,
                pid: '',
                create_time: '',
                uid: '',
                id: ''
            });
            let imgs = ref([]);
            const comments = ref([]);
            const submitting = ref(false);
            const value = ref('');
            const router = useRouter();
            const id = reactive({'id': router.currentRoute.value.params.id});
            const isDisabled = ref(false);
            onMounted(() => {
                detail(id).then((response) => {
                    if (response.status == 'done') {
                        //console.log(1111+ formState);
                        formState.value = response.detail;
                        formState.value.create_time = moment.unix(formState.value.create_time).format('YYYY-MM-DD HH:mm:ss');
                        if (formState.value.complete == 1) {
                            isDisabled.value = true;
                        }
                        arr();
                        getImgContent();
                        getmessage();
                    } else if (response.status == 'error') {
                        message.error(response.msg);
                    }
                })
            })
            const getmessage = () => {
                getMsg(id).then((response) => {
                    if (response.status == 'done') {
                        comments.value = response.message;
                    } else if (response.status == 'error') {
                        message.error(response.msg);
                    }
                })
            }
            const arr = () => {
                //console.log(1111111);
                const arr = formState.value.content.split(',');
                let content = '';
                let item = '';
                for (item of arr) {
                    if (item == 1) {
                        content += 'logo ';
                    } else if (item == 2) {
                        content += 'banner ';
                    } else if (item == 3) {
                        content += '产品 ';
                    } else if (item == 4) {
                        content += '支付';
                    }
                }
                formState.value.content = content;
            };
            const getImgContent = () => {
                const pid = {'pid': formState.value.pid};
                getImg(pid).then((response) => {
                    if (response.status == 'done') {
                        imgs.value = response.pic.pic.split(',');
                        //console.log(imgs);
                    } else if (response.status == 'error') {
                        console.log(response.msg);
                    }
                });
            };

            const avatar = window.sessionStorage.getItem('uname').substring(0, 1);
            const handleSubmit = () => {
                const myDate = new Date();
                const currentTime = myDate.getTime();
                if (!value.value) {
                    return;
                }
                submitting.value = true;
                setTimeout(() => {
                    submitting.value = false;
                    comments.value = [{
                        uname: window.sessionStorage.getItem('uname'),
                        content: value.value,
                        create_time: moment(parseInt(currentTime)).format('YYYY/MM/DD HH:mm:ss'),
                        ufrom: window.sessionStorage.getItem('uid'),
                        oid: router.currentRoute.value.params.id
                    }, ...comments.value];
                    addMsg(comments.value[0]).then((response) => {
                        if (response.status == 'done') {
                            getmessage();
                            message.success(response.msg);
                        } else if (response.status == 'error') {
                            message.error(response.msg);
                        }
                    })
                    value.value = '';
                }, 1000);
            }
            //console.log(2222 + baseUrl.apiUrl);
            return {
                comments,
                submitting,
                value,
                handleSubmit,
                formState,
                labelCol: {
                    span: 4,
                },
                wrapperCol: {
                    span: 14,
                },
                isDisabled,
                imgs,
                baseUrl: baseUrl.apiUrl,
                avatar
            };
        },
    })
    ;
</script>
<style scoped lang="less">
    .bg {
        background: #fff;
        padding: 30px 0 0;

        .ml {
            margin-right: 20px;
            margin-bottom: 20px;
        }
    }

    .bg1 {
        background: #fff;
        margin: 20px 0 0;
        padding: 0 30px;

        .tw {
            width: 1000px;
        }

        .abg {
            background: orange;
        }
    }
</style>

import {createRouter, createWebHistory} from 'vue-router'
import Submit from '../components/custom/Submit.vue'
import List from '../components/custom/List.vue'
import Detail from '../components/custom/Detail.vue'
import Slist from '../components/service/List.vue'
import Sdetail from '../components/service/Detail.vue'
import Add from '../components/service/Add.vue'
import Userlist from '../components/service/UserList.vue'
import store from "@/store/index"
import {message} from 'ant-design-vue'

const routes = [
    {
        path: '/',
        name: 'Custom',
        component: () => import(/* webpackChunkName: "about" */ '../views/Custom.vue'),
        redirect: '/submit',
        children: [{
            path: '/submit',
            component: Submit,
            name: 'Submit',
            meta: {
                title: '提交工单',
                isAuth: true,
                role: 2
            }
        },
            {
                path: '/list',
                component: List,
                name: 'List',
                hidden: true,
                meta: {
                    title: '工单列表',
                    role: 2,
                    isAuth: true
                }
            },
            {
                path: '/detail/id/:id',
                component: Detail,
                name: 'Detail',
                hidden: true,
                meta: {
                    title: '工单详情',
                    role: 2,
                    isAuth: true
                }
            }]
    },
    {
        path: '/login',
        name: 'Login',
        meta: {
            title: '工单登录页',

        },
        component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
    },
    {
        path: '/service',
        name: 'Service',
        component: () => import(/* webpackChunkName: "about" */ '../views/Service.vue'),
        redirect: '/slist',
        children: [{
            path: '/slist',
            component: Slist,
            name: 'Slist',
            meta: {
                title: '工单列表',
                role: 1,
                isAuth: true
            }
        }, {
            path: '/userlist',
            component: Userlist,
            name: 'Userlist',
            hidden: true,
            meta: {
                title: '用户列表',
                role: 1,
                isAuth: true
            }
        },
            {
                path: '/add',
                component: Add,
                name: 'Add',
                hidden: true,
                meta: {
                    title: '添加用户',
                    role: 1,
                    isAuth: true
                }
            }, {
                path: '/sdetail/id/:id',
                component: Sdetail,
                name: 'Sdetail',
                hidden: true,
                meta: {
                    title: '工单详情',
                    role: 1,
                    isAuth: true
                }
            }]
    },
    {
        path: '/:pathMatch(.*)',
        name: '404',
        component: () => import('../views/404.vue'),
    }

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})
router.beforeEach((to, from, next) => {
    // to 访问的路径 from 从哪来 next 响应路径

    if (to.meta.isAuth && store.state.user.isLogin === false&& to.meta.role != window.sessionStorage.getItem('role')) {
        window.sessionStorage.clear();
        message.error('还未登录，跳转到登录页')
        return next('../views/login')
    }
    else{
        if(to.meta.role != window.sessionStorage.getItem('role')){
            window.sessionStorage.clear();
            message.error('身份不一致，跳转到登录页')
            return next('../views/login')
        }else{
            next()
        }
    }
    /*if(to.meta.role != window.sessionStorage.getItem('role')){
        window.sessionStorage.clear();
        message.error('身份不一致，跳转到登录页')
        return next('../views/login')
    } else{
        if (to.meta.isAuth && store.state.user.isLogin === false&& to.meta.role != window.sessionStorage.getItem('role')) {
            window.sessionStorage.clear();
            message.error('还未登录，跳转到登录页')
            return next('../views/login')
        }else{
            next()
        }
    }*/
    document.title = to.meta.title
})
export default router

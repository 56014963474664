<template>
    <a-table :columns="columns" :data-source="data" :scroll="{ x: 1500, y: 600 }" ><!--rowKey="columnId"-->
        <template #site="{record}">
            <a :href="record.site" target="_blank">{{record.site}}</a><br/>
        </template>
        <template #refer="{record}">
            <a :href="record.refer" target="_blank">{{record.refer}}</a><br/>
        </template>
        <template #complete="{record}">
            <p v-if="record.complete == 1">
                <span class="red">是</span>
            </p>
            <p v-else>
                <span>否</span>
            </p>
        </template>
        <template #action="{record}">
            <router-link :to="'/detail/id/'+record.id">详情</router-link>
        </template>
    </a-table>
</template>
<script>
    import {defineComponent, onMounted, ref, reactive} from 'vue';
    import {useStore} from 'vuex';
    import {list} from '@/network/custom';
    import moment from 'moment';
    //import {useRouter} from 'vue-router';
    import {message} from 'ant-design-vue'

    export default defineComponent({
        name: "List",
        setup() {
            const columns = [
                {
                    title: 'Id',
                    width: 90,
                    dataIndex: 'id',
                    key: 'id',
                    fixed: 'left',
                },
                {
                    title: '要修改的站',
                    dataIndex: 'site',
                    key: 'site',
                    width: 80,
                    slots: {
                        customRender: 'site',
                    },
                },
                {
                    title: '参考的网站',
                    dataIndex: 'refer',
                    key: 'refer',
                    width: 80,
                    slots: {
                        customRender: 'refer',
                    },
                },
                {
                    title: '修改内容',
                    dataIndex: 'content',
                    key: 'content',
                    width: 120,
                    customRender: (text, record, index) => {
                        const arr = text.text.split(',');
                        let content = '';
                        let item = '';
                        for (item of arr){
                            if(item == 1){
                                content += 'logo ';
                            }else if (item == 2){
                                content += 'banner ';
                            }else if (item == 3){
                                content += '产品 ';
                            }else if (item == 4){
                                content += '支付';
                            }
                        }
                        return content;
                    }
                },
                {
                    title: '保留修改网站订单',
                    dataIndex: 'orders',
                    key: 'orders',
                    width: 80,
                    customRender: (text, record, index) => {
                        //console.log(text);
                        if (text.text == 1) {
                            return "是";
                        } else {
                            return "否";
                        }
                    }

                },
                {
                    title: '网站修改备注',
                    dataIndex: 'description',
                    key: 'description',
                    width: 150,
                    ellipsis: true,
                },
                {
                    title: '是否完成',
                    dataIndex: 'complete',
                    key: 'complete',
                    width: 50,
                    slots: {
                        customRender: 'complete',
                    },
                    /*customRender: (text, record, index) => {

                        if (text.text == 1) {
                            return "是";//<span class='red'>是</span>
                        } else {
                            return "否";
                        }
                    }*/
                },
                {
                    title: '提交时间',
                    dataIndex: 'create_time',
                    key: 'create_time',
                    width: 100,
                    customRender: (text, record, index) => {
                        console.log(text.text);
                        return moment.unix(text.text).format('YYYY-MM-DD HH:mm:ss');
                    }
                },
                {
                    title: '操作',
                    key: 'operation',
                    fixed: 'right',
                    width: 120,
                    slots: {
                        customRender: 'action',
                    },
                },
            ];
            const store = useStore();
            const data = ref([]);
            const uid = reactive({'uid': window.sessionStorage.getItem('uid')});
            //console.log(uid.value);
            onMounted(() => {
                list(uid).then((response) => {
                    if(response.status=='done'){
                        data.value = response.list;
                    }else{
                        message.error(response.msg);
                    }
                })
            });
            //const router = useRouter();
            /*const jump = (id)=>{
                router.push({path:'/detail',query:{id:id}});
            };*/
            return {
                // jump,
                data,
                columns,
            };
        },
    });
</script>
<style scoped lang="less">
</style>
